import React, { useState } from "react";
import { connect } from 'react-redux';
import { createAlertEvent, updateAlertEvent } from '../../../../actions/alert/alert-event';
import styled from 'styled-components';
import { AlertEvent } from "../alert-types";
import Modal from 'react-modal';
import AlertEventForm from "../AlertEventForm";
import { createAlertHumor, updateAlertHumor } from "../../../../actions/alert/alert-humor";
import colors from '../../../../styles/colors';

const TitleStyle = styled.div`
  line-height: 30px;
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 0;
`;

const DateStyle = styled.div`
  line-height: 27px;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 0 0 0;
`;

const SpeakerStyle = styled.div`
  line-height: 27px;
  color: #000000;
  font-size: 18px;
  padding: 10px 0;
`;

const ImageStyle = styled.div`
  img {
    width: 100%;
  }
`;

const BodyStyle = styled.div`
  padding: 10px 0;
  line-height: 22px;
  text-align: justify;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LearnMoreLink = styled.div`
  border: 1px solid ${colors.linkBlue};
  padding: 3px 9px 6px 9px;
  border-radius: 10px;
  cursor: pointer;
  :hover {
      opacity: .6;
    }
  a {
    text-decoration: none;
    color: ${colors.linkBlue};
    font-size: 14px;
  }
`;

const CitationStyle = styled.div`
  font-style: italic;
  color: ${colors.gray300};
  font-size: 12px;
`;

const ViewMoreContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  a {
    color: ${colors.gray400};
  }
  img {
    padding-right: 5px;
  }
`;

const EditRightContainer = styled.div`
  display: flex;
  justify-content: right;
  padding-top: 10px;
`;

const EditButton = styled.div`
  border: 1px solid red;
  padding: 3px 9px 6px 9px;
  border-radius: 10px;
  color: red;
  cursor: pointer;
`;

const AddNewButton = styled.div`
  color: red;
  cursor: pointer;
  text-align: right;
`;

const FeaturedContentStyle = styled.div`
  /* font-style: italic; */
  color: ${colors.gray300};
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  p {
    padding: 0;
    margin: 0;
  }
`;

interface Props {
  alertItemId: number;
  alertEvent: AlertEvent;
  contentType: string;
  buttonTitle: string;
  createAlertEvent: (alertEvent: AlertEvent) => void;
  updateAlertEvent: (alertEvent: AlertEvent) => void;
}

const AlertEventComponent: React.FC<Props> = ({ alertItemId, alertEvent, contentType, buttonTitle, createAlertEvent, updateAlertEvent }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newAlertEvent, setNewAlertEvent] = useState<AlertEvent>({ id: 0, title: '', body: '', image: '', speaker: '', link: '', imageLink: '', viewMoreLink: '', alertItemId: 0, date: '', attribution: '', featuredEvent: false });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '900px'
    },
  };

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleEdit = (alertEvent: AlertEvent) => {
    setNewAlertEvent(alertEvent);
    setModalIsOpen(true);
  }

  const handleSave = (alertEvent: AlertEvent) => {
    createAlertEvent(alertEvent);
    closeModal();
  }

  const handleUpdate = (alertEvent: AlertEvent) => {
    updateAlertEvent(alertEvent);
    closeModal();
  }

  return (
    <>
      {
        (alertEvent && Object.keys(alertEvent).length > 0) ?
          <>
            <TitleStyle>{alertEvent.title}</TitleStyle>
            <ImageStyle><a href={alertEvent.link}>
              <img src={`https://${alertEvent.image}`} alt="" /></a>
            </ImageStyle>
            {
              alertEvent.featuredEvent &&
              <FeaturedContentStyle>
                Sponsored
              </FeaturedContentStyle>
            }
            <DateStyle>{alertEvent.date}</DateStyle>
            {
              alertEvent.speaker &&
              <SpeakerStyle>Speaker: {alertEvent.speaker}</SpeakerStyle>
            }
            <BodyStyle><div dangerouslySetInnerHTML={{ __html: alertEvent.body }} /></BodyStyle>
            <ButtonContainer>
              <LearnMoreLink><a href={alertEvent.link}>Learn More</a></LearnMoreLink>
            </ButtonContainer>
            <ViewMoreContainer>
              <img src="https://dir80cd32z1m2.cloudfront.net/email/images/calendar.png" />
              <a href="https://aspenalert.com/events-calendar">View Events</a>
            </ViewMoreContainer>
            <EditRightContainer>
              <EditButton onClick={() => handleEdit(alertEvent)}>Edit</EditButton>
            </EditRightContainer>
          </>
          : <AddNewButton onClick={openModal}>{buttonTitle}</AddNewButton>
      }
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <AlertEventForm
          handleSave={handleSave}
          alertItemId={alertItemId}
          alertEvent={newAlertEvent}
          handleUpdate={handleUpdate}
          pageTitle={buttonTitle}
        />
      </Modal>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    //   alertEvent: state.alertEvent.data
    // loading: state.accountInfo.loading,
    // error: state.accountInfo.error,
    // errorMessage: state.accountInfo.errorMessage,
    // microsite: state.microsite.data,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  createAlertEvent: (alertEvent: AlertEvent) => dispatch(createAlertEvent(alertEvent)),
  updateAlertEvent: (alertEvent: AlertEvent) => dispatch(updateAlertEvent(alertEvent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertEventComponent);