import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding, CheckboxStyling } from '../../../styles/form-styles.js';
import colors from '../../../styles/colors.js';
import ABButton from '../../../components/ABButton.js';
import { history } from '../../../routers/AppRouter.js';
import ABInputField from '../../../components/ABInputField';
import { AlertEvent } from './alert-types.js';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ABDropzone from '../../../components/ABDropzone';
import { ToastContainer, toast } from 'react-toastify';

const PageWrapper = styled.div`
  margin: 0 auto;
  padding: 20px;
  min-height: 900px;
`;

const FormFlex = styled.div`
    display: flex;
    justify-content: space-between;
    width: 750px;
`;

const AlertFormLeft = styled.div`
    /* width: 360px; */
`;

const AlertFormRight = styled.div`
    /* width: 360px; */
    padding-left: 40px;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 0 0 10px 0;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  justify-content: flex-end;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
  justify-content: flex-end;
`;

const StyledTextArea = styled.textarea`
  font-size: 16px;
  padding: 10px;
`;

const DefaultImagePreviewStyle = styled.div`
  img {
    width: 100px;
    height: 100px;
  }
`;

interface AlertEventProps {
    alertItemId: number;
    pageTitle: string;
    handleSave: (alertEvent: AlertEvent) => void;
    handleUpdate: (alertEvent: AlertEvent) => void;
    alertEvent: AlertEvent
}

const AlertEventForm: React.FC<AlertEventProps> = ({ alertEvent, alertItemId, pageTitle, handleSave, handleUpdate }) => {

    const [id, setId] = useState(0);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [image, setImage] = useState('');
    const [imageUploaded, setImageUploaded] = useState(false);
    const [speaker, setSpeaker] = useState('');
    const [link, setLink] = useState('');
    const [imageLink, setImageLink] = useState('');
    const [viewMoreLink, setViewMoreLink] = useState('');
    const [date, setDate] = useState('');
    const [attribution, setAttribution] = useState('');
    const [clientId, setClientId] = useState(0);
    const [featuredEvent, setFeaturedEvent] = useState(false);
    const [editing, setEditing] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        // check if editing
        if (alertEvent.id !== 0) {
            setId(alertEvent.id);
            setEditing(true);
            setTitle(alertEvent.title);
            setBody(alertEvent.body);
            setImage(alertEvent.image);
            if (alertEvent.speaker) {
                setSpeaker(alertEvent.speaker);
            }
            setLink(alertEvent.link);
            setImageLink(alertEvent.imageLink);
            // setViewMoreLink(alertEvent.viewMoreLink);
            setDate(alertEvent.date);
            setAttribution(alertEvent.attribution);
            setClientId(alertEvent.clientId || 0)

            const contentBlock = htmlToDraft(alertEvent.body);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
            if (alertEvent.featuredEvent) {
                setFeaturedEvent(alertEvent.featuredEvent);
            }
        }
    }, [])

    const onSave = () => {

        if (imageUploaded && !image) {
            toast.error('Error Uploading Image');
            return;
        }

        const editorBody = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        const alertEvent: AlertEvent = {
            id,
            title,
            body: editorBody,
            image,
            speaker,
            link,
            imageLink,
            alertItemId: alertItemId,
            date,
            attribution,
            clientId
        }

        if (featuredEvent) {
            alertEvent.featuredEvent = featuredEvent
        }

        if (editing) {
            handleUpdate(alertEvent);
        } else {
            handleSave(alertEvent);
        }
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const title = e.currentTarget.value;
        setTitle(title);
    };

    const handleBodyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const body = e.currentTarget.value;
        setBody(body);
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const image = e.currentTarget.value;
        setImage(image);
    };

    const handleSpeakerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const speaker = e.currentTarget.value;
        setSpeaker(speaker);
    };

    const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const link = e.currentTarget.value;
        setLink(link);
    };

    const handleImageLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const imageLink = e.currentTarget.value;
        setImageLink(imageLink);
    };

    const handleViewMoreLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const viewMoreLink = e.currentTarget.value;
        setViewMoreLink(viewMoreLink);
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const date = e.currentTarget.value;
        setDate(date);
    };

    const handleAttributionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const attribution = e.currentTarget.value;
        setAttribution(attribution);
    };

    const handleClientIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const clientId = parseInt(e.currentTarget.value, 10);
        setClientId(clientId);
    };

    const onEditorStateChange = (editorState: any) => {
        setEditorState(editorState);
    };

    const handleFilesDrop = (cloudfrontUrl: string) => {
        setImage(cloudfrontUrl);
        setImageUploaded(true);
    };

    const handleFeaturedToggle = () => {
        setFeaturedEvent((prevIsOn) => !prevIsOn);
    };

    const toolbarConfig = {
        options: [
            'inline',
            'textAlign',
            'link'
        ],
        inline: {
            options: ['bold', 'italic', 'underline'],
        },
        textAlign: {
            options: ['left', 'center', 'right', 'justify'],
        },
        link: {
            options: ['link', 'unlink'],
        }
    };

    return (
        <>
            <PageWrapper>
                <PageHeading>
                    <PageTitle>{pageTitle}</PageTitle>
                </PageHeading>

                <FormFlex>
                    <AlertFormLeft>
                        <FormSubHeading>Title:</FormSubHeading>
                        <ABInputField
                            value={title}
                            onChange={handleTitleChange}
                            placeholder="Title"
                        />

                        <FormSubHeading>Body:</FormSubHeading>
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="post-editor"
                            onEditorStateChange={onEditorStateChange}
                            toolbar={toolbarConfig}
                            stripPastedStyles={true}
                        />
                    </AlertFormLeft>

                    <AlertFormRight>
                        <FormSubHeading>Image:</FormSubHeading>
                        <ABDropzone onFilesDrop={handleFilesDrop} maxWidth={600} />

                        {
                            (image && !imageUploaded) &&
                            <DefaultImagePreviewStyle>
                                <FormSubHeading>Image Preview:</FormSubHeading>
                                <img src={`https://${image}`} />
                            </DefaultImagePreviewStyle>
                        }

                        <FormSubHeading>Learn More Link:</FormSubHeading>
                        <ABInputField
                            value={link}
                            onChange={handleLinkChange}
                            placeholder="Link"
                        />

                        <CheckboxStyling>
                            <FormSubHeading>Sponsored:</FormSubHeading>
                            <input
                                type="checkbox"
                                checked={featuredEvent}
                                onChange={handleFeaturedToggle}
                            />
                        </CheckboxStyling>

                        <FormSubHeading>Image Link:</FormSubHeading>
                        <ABInputField
                            value={imageLink}
                            onChange={handleImageLinkChange}
                            placeholder="Image Link"
                        />

                        <FormSubHeading>Speaker:</FormSubHeading>
                        <ABInputField
                            value={speaker}
                            onChange={handleSpeakerChange}
                            placeholder="Speaker"
                        />

                        <FormSubHeading>Date:</FormSubHeading>
                        <ABInputField
                            value={date}
                            onChange={handleDateChange}
                            placeholder="Date"
                        />

                        {/* <FormSubHeading>View More Link:</FormSubHeading>
                        <ABInputField
                            value={viewMoreLink}
                            onChange={handleViewMoreLinkChange}
                            placeholder="View More Link"
                        /> */}

                        <FormSubHeading>Attribution Tracking:</FormSubHeading>
                        <ABInputField
                            value={attribution}
                            onChange={handleAttributionChange}
                            placeholder="Attribution"
                        />

                        <FormSubHeading>Client ID:</FormSubHeading>
                        <ABInputField
                            value={clientId || 0}
                            onChange={handleClientIdChange}
                            placeholder="Client ID"
                        />
                    </AlertFormRight>
                </FormFlex>

                <ActionButtons>
                    <RightButton>
                        <ABButton title="Save" onClick={() => onSave()} />
                    </RightButton>
                </ActionButtons>
                <ToastContainer />
            </PageWrapper>
        </>
    );
}

export default AlertEventForm;
