import React, { useState } from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AlertPatent } from "../alert-types";
import Modal from 'react-modal';
import { createAlertPatent, updateAlertPatent } from "../../../../actions/alert/alert-patent";
import { createAlertBioprocess, updateAlertBioprocess } from "../../../../actions/alert/alert-bioprocess";
import AlertPatentForm from "../AlertPatentForm";
import { createAlertUpstream, updateAlertUpstream } from "../../../../actions/alert/alert-upstream";
import { createAlertDownstream, updateAlertDownstream } from "../../../../actions/alert/alert-downstream";
import { createAlertFill, updateAlertFill } from "../../../../actions/alert/alert-fill";
import { createAlertSpotlight, updateAlertSpotlight } from "../../../../actions/alert/alert-spotlight";
import { createAlertSolution, updateAlertSolution } from "../../../../actions/alert/alert-solution";
import colors from "../../../../styles/colors";

interface ImageStyleProps {
  width?: number;
}

const TitleStyle = styled.div`
  line-height: 30px;
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 0;
`;

const ImageStyle = styled.div<ImageStyleProps>`
  text-align: center;
  img {
    width: ${props => props.width ? props.width : '100%'};
    /* width: 100%; */
  }
`;

const BodyStyle = styled.div`
  padding: 10px 0;
  line-height: 22px;
  text-align: justify;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ViewMoreContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  a {
    color: ${colors.gray400};
  }
  img {
    padding-right: 5px;
  }
`;

const EditRightContainer = styled.div`
  display: flex;
  justify-content: right;
  padding-top: 10px;
`;

const ViewMoreButton = styled.div`
  color: #154f91;
  cursor: pointer;
`;

const LearnMoreLink = styled.div`
  border: 1px solid ${colors.linkBlue};
  padding: 3px 9px 6px 9px;
  border-radius: 10px;
  cursor: pointer;
  :hover {
      opacity: .6;
    }
  a {
    text-decoration: none;
    color: ${colors.linkBlue};
    font-size: 14px;
  }
`;

const EditButton = styled.div`
  border: 1px solid red;
  padding: 3px 9px 6px 9px;
  border-radius: 10px;
  color: red;
  cursor: pointer;
`;

const AddNewButton = styled.div`
  color: red;
  cursor: pointer;
  text-align: right;
`;

const FeaturedContentStyle = styled.div`
  /* font-style: italic; */
  color: ${colors.gray300};
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  p {
    padding: 0;
    margin: 0;
  }
`;

interface Props {
  alertItemId: number;
  alertPatent: AlertPatent;
  contentType: string;
  buttonTitle: string;
  createAlertPatent: (alertPatent: AlertPatent) => void;
  updateAlertPatent: (alertPatent: AlertPatent) => void;
  createAlertBioprocess: (alertPatent: AlertPatent) => void;
  updateAlertBioprocess: (alertPatent: AlertPatent) => void;
  createAlertUpstream: (alertPatent: AlertPatent) => void;
  updateAlertUpstream: (alertPatent: AlertPatent) => void;
  createAlertDownstream: (alertPatent: AlertPatent) => void;
  updateAlertDownstream: (alertPatent: AlertPatent) => void;
  createAlertFill: (alertPatent: AlertPatent) => void;
  updateAlertFill: (alertPatent: AlertPatent) => void;
  createAlertSpotlight: (alertPatent: AlertPatent) => void;
  updateAlertSpotlight: (alertPatent: AlertPatent) => void;
  createAlertSolution: (alertPatent: AlertPatent) => void;
  updateAlertSolution: (alertPatent: AlertPatent) => void;
}

const AlertComponentD: React.FC<Props> = ({ alertItemId, alertPatent, contentType, buttonTitle, createAlertPatent, updateAlertPatent, createAlertBioprocess, updateAlertBioprocess, createAlertUpstream, updateAlertUpstream, createAlertDownstream, updateAlertDownstream,
  createAlertFill, updateAlertFill, createAlertSpotlight, updateAlertSpotlight, createAlertSolution, updateAlertSolution }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newAlertPatent, setNewAlertPatent] = useState<AlertPatent>({ id: 0, title: '', body: '', image: '', link: '', imageLink: '', viewMoreLink: '', alertItemId: 0, attribution: '', featuredContent: false });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '900px'
    },
  };

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleEdit = (alertPatent: AlertPatent) => {
    setNewAlertPatent(alertPatent);
    setModalIsOpen(true);
  }

  const handleSave = (alertPatent: AlertPatent) => {
    if (contentType === 'patent') {
      createAlertPatent(alertPatent);
    }
    if (contentType === 'bioprocess') {
      createAlertBioprocess(alertPatent);
    }
    if (contentType === 'upstream') {
      createAlertUpstream(alertPatent);
    }
    if (contentType === 'downstream') {
      createAlertDownstream(alertPatent);
    }
    if (contentType === 'fill') {
      createAlertFill(alertPatent);
    }
    if (contentType === 'spotlight') {
      createAlertSpotlight(alertPatent);
    }
    if (contentType === 'solution') {
      createAlertSolution(alertPatent);
    }
    closeModal();
  }

  const handleUpdate = (alertPatent: AlertPatent) => {
    if (contentType === 'patent') {
      updateAlertPatent(alertPatent);
    }
    if (contentType === 'bioprocess') {
      updateAlertBioprocess(alertPatent);
    }
    if (contentType === 'upstream') {
      updateAlertUpstream(alertPatent);
    }
    if (contentType === 'downstream') {
      updateAlertDownstream(alertPatent);
    }
    if (contentType === 'fill') {
      updateAlertFill(alertPatent);
    }
    if (contentType === 'spotlight') {
      updateAlertSpotlight(alertPatent);
    }
    if (contentType === 'solution') {
      updateAlertSolution(alertPatent);
    }
    closeModal();
  }

  return (
    <>
      {
        (alertPatent && Object.keys(alertPatent).length > 0) ?
          <>
            <TitleStyle>{alertPatent.title}</TitleStyle>
            {
              alertPatent.imageWidth &&
              <ImageStyle width={alertPatent.imageWidth}> <a href={alertPatent.link}><img src={`https://${alertPatent.image}`} alt="" width={alertPatent.imageWidth} /></a></ImageStyle>
            }
            {
              !alertPatent.imageWidth &&
              <ImageStyle> <a href={alertPatent.link}><img src={`https://${alertPatent.image}`} alt="" /></a></ImageStyle>
            }
            {
              alertPatent.featuredContent &&
              <FeaturedContentStyle>
                Sponsored
              </FeaturedContentStyle>
            }
            <BodyStyle><div dangerouslySetInnerHTML={{ __html: alertPatent.body }} /></BodyStyle>
            <ButtonContainer>
              <LearnMoreLink><a href={alertPatent.link}>Learn More</a></LearnMoreLink>
            </ButtonContainer>
            <ViewMoreContainer>
              <img src="https://dir80cd32z1m2.cloudfront.net/email/images/library.png" />
              <a href="https://aspenalert.com/atheneum" target="_blank">View Atheneum</a>
            </ViewMoreContainer>
            <EditRightContainer>
              <EditButton onClick={() => handleEdit(alertPatent)}>Edit</EditButton>
            </EditRightContainer>
          </>
          : <AddNewButton onClick={openModal}>{buttonTitle}</AddNewButton>
      }
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <AlertPatentForm
          handleSave={handleSave}
          alertItemId={alertItemId}
          alertPatent={newAlertPatent}
          handleUpdate={handleUpdate}
          pageTitle={buttonTitle}
        />
      </Modal>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    //   alertPatent: state.alertPatent.data
    // loading: state.accountInfo.loading,
    // error: state.accountInfo.error,
    // errorMessage: state.accountInfo.errorMessage,
    // microsite: state.microsite.data,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  createAlertPatent: (alertPatent: AlertPatent) => dispatch(createAlertPatent(alertPatent)),
  updateAlertPatent: (alertPatent: AlertPatent) => dispatch(updateAlertPatent(alertPatent)),
  createAlertBioprocess: (alertPatent: AlertPatent) => dispatch(createAlertBioprocess(alertPatent)),
  updateAlertBioprocess: (alertPatent: AlertPatent) => dispatch(updateAlertBioprocess(alertPatent)),
  createAlertUpstream: (alertPatent: AlertPatent) => dispatch(createAlertUpstream(alertPatent)),
  updateAlertUpstream: (alertPatent: AlertPatent) => dispatch(updateAlertUpstream(alertPatent)),
  createAlertDownstream: (alertPatent: AlertPatent) => dispatch(createAlertDownstream(alertPatent)),
  updateAlertDownstream: (alertPatent: AlertPatent) => dispatch(updateAlertDownstream(alertPatent)),
  createAlertFill: (alertPatent: AlertPatent) => dispatch(createAlertFill(alertPatent)),
  updateAlertFill: (alertPatent: AlertPatent) => dispatch(updateAlertFill(alertPatent)),
  createAlertSpotlight: (alertPatent: AlertPatent) => dispatch(createAlertSpotlight(alertPatent)),
  updateAlertSpotlight: (alertPatent: AlertPatent) => dispatch(updateAlertSpotlight(alertPatent)),
  createAlertSolution: (alertPatent: AlertPatent) => dispatch(createAlertSolution(alertPatent)),
  updateAlertSolution: (alertPatent: AlertPatent) => dispatch(updateAlertSolution(alertPatent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertComponentD);